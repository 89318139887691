import { Observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";

import { ITheme, mergeStyles, Stack, useTheme } from "@bps/fluent-ui";
import { useGoogleAnalyticsPageTracking } from "@libs/analytics/google/index.tsx";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { QuickLinks } from "@modules/clinical/screens/quick-links/QuickLinks.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

import { ServiceWorkerRegistration } from "../../ServiceWorkerRegistration.tsx";
import { NavBar } from "../NavBar/NavBar.tsx";
import { InactivityPinDialog } from "../session/components/InactivityPinDialogForm/InactivityPinDialog.tsx";
import { useTitle } from "../session/hooks/useTitle.tsx";
import { Dialogs } from "./Dialogs.tsx";
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback.tsx";
import { Messages } from "./Messages.tsx";

const getMainStyles = (theme: ITheme) =>
  mergeStyles({
    flexGrow: 1,
    backgroundColor: theme.semanticColors.disabledBackground,
    overflow: "auto",
    padding: 16,
    position: "relative"
  });

export const MainLayout: FunctionComponent = () => {
  const theme = useTheme();
  const { userExperience } = useStores();

  useGoogleAnalyticsPageTracking();
  useTitle();

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Stack verticalFill>
        <ServiceWorkerRegistration />
        <When permission={Permission.InactiveLockScreenAllowed}>
          <InactivityPinDialog />
        </When>
        <NavBar />
        <Observer>
          {() => <>{!userExperience.quickLinksOnBottom && <QuickLinks />}</>}
        </Observer>
        <Messages />
        <main className={getMainStyles(theme)}>
          <Outlet />
          <Dialogs />
        </main>

        <Observer>
          {() => <>{userExperience.quickLinksOnBottom && <QuickLinks />}</>}
        </Observer>
      </Stack>
    </ErrorBoundary>
  );
};
